import React, { useEffect, useState } from "react";
import { Continue } from "./Continue";
import { Action, State } from "../state";
import client from "../client";
import { SelectLogoModal } from "./SelectLogoModal";

type Logo = { id: string; url: string };

export const CreateText: React.FC<{
  dispatch: React.Dispatch<Action>;
  state: State;
}> = ({ dispatch, state }) => {
  const MinTextLength = 400;

  function titleChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      id: "setTitle",
      data: { title: event.target.value },
    });
  }

  function textChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
    dispatch({
      id: "setText",
      data: { text: event.target.value },
    });
  }

  function textSourceTextChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      id: "setTextSourceText",
      data: { textSourceText: event.target.value },
    });
  }

  function textSourceUrlChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      id: "setTextSourceUrl",
      data: { textSourceUrl: event.target.value },
    });
  }

  const [showLogoModal, setShowLogoModal] = useState<boolean>(false);
  const [logos, setLogos] = useState<Logo[]>([]);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState<string>();

  useEffect(() => {
    async function loadLogos() {
      if (showLogoModal) {
        const service = client.service("file");
        const logoFiles = await service.find({ query: { category: "textSourceLogo" } });
        const logos_: Logo[] = [];
        logoFiles.forEach((l: any) =>
          logos_.push({
            id: l.id,
            url: `https://${process.env.REACT_APP_S3_BUCKET}.s3.eu-central-1.amazonaws.com/${l.filename_storage}`,
          })
        );
        setLogos(logos_);
      }
    }

    loadLogos();
  }, [showLogoModal]);

  return (
    <div className="flex flex-col grow items-center space-y-4 p-4 bg-white">
      <input
        className="w-full bg-light-gray text-dark font-bold placeholder-normal-gray border-0 focus:ring-0 rounded-md"
        type="text"
        placeholder="Titel"
        maxLength={50}
        value={state.title}
        onChange={titleChanged}
      />
      <p className="text-sm w-full text-normal-gray pl-2">
        mind. {MinTextLength} /{" "}
        <span className="font-bold text-sprinkle">
          {state.text === undefined ? 0 : state.text.length}
        </span>{" "}
        / max. 1&apos;500
      </p>
      <textarea
        className="w-full grow bg-light-gray text-dark placeholder-normal-gray border-0 focus:ring-0 rounded-md resize-none overflow-hidden"
        placeholder="Hier Text einfügen, schreiben ...&#10;Text wird automatisch in Audio umgewandelt"
        value={state.text}
        maxLength={1500}
        onChange={textChanged}
      ></textarea>
      {state.text && state.text.length < MinTextLength && (
        <div className="h-20">
          <span className="font-bold text-sprinkle bg-medium-gray rounded-md px-4 text-center">
            {MinTextLength} Zeichen noch nicht erreicht
          </span>
        </div>
      )}
      <div className="flex flex-row">
        <button
          className="w-32 h-32 text-gray-400 border rounded-full"
          onClick={() => setShowLogoModal(true)}
        >
          {selectedLogoUrl !== undefined || state.textSourceLogoBase64String !== undefined ? (
            <img
              className="w-32 h-32 rounded-full"
              src={
                selectedLogoUrl !== undefined ? selectedLogoUrl : state.textSourceLogoBase64String
              }
              alt="Logo"
            />
          ) : (
            <p>
              Logo
              <br />
              optional
            </p>
          )}
        </button>
        <button
          className="w-6 h-6 rounded-full border text-sm text-gray-400 self-end"
          onClick={() => {
            setSelectedLogoUrl(undefined);
            dispatch({
              id: "setTextSourceLogo",
              data: { textSourceLogoId: undefined },
            });
          }}
        >
          x
        </button>
      </div>
      <input
        className="w-full bg-light-gray text-dark placeholder-normal-gray border-0 focus:ring-0 rounded-md"
        type="text"
        placeholder="Quelle Text - optional"
        maxLength={50}
        value={state.textSourceText}
        onChange={textSourceTextChanged}
      />
      <input
        className="w-full bg-light-gray text-dark placeholder-normal-gray border-0 focus:ring-0 rounded-md"
        type="text"
        placeholder="Quelle URL - optional"
        maxLength={2048}
        value={state.textSourceUrl}
        onChange={textSourceUrlChanged}
      />
      {state.text &&
        state.title &&
        state.text.length >= MinTextLength &&
        state.title.length > 0 && <Continue to="../picture" visible={true} />}
      {showLogoModal && (
        <SelectLogoModal
          logos={logos}
          onSelected={(logoId?: string, logoUrl?: string, logoBase64String?: string) => {
            if (logoId !== undefined && logoUrl !== undefined) {
              dispatch({
                id: "setTextSourceLogo",
                data: { textSourceLogoId: logoId },
              });
              setSelectedLogoUrl(logoUrl);
            } else if (logoBase64String !== undefined) {
              dispatch({
                id: "setTextSourceLogo",
                data: { textSourceLogoBase64String: logoBase64String },
              });
              setSelectedLogoUrl(undefined);
            }
          }}
          onClose={() => setShowLogoModal(false)}
        />
      )}
    </div>
  );
};
