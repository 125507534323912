import React from "react";

export const TakePictureButton: React.FC<{
  onClick: React.MouseEventHandler;
}> = ({ onClick }) => {
  return (
    <div
      className="flex flex-col justify-center items-center cursor-pointer w-24 h-24 rounded-full bg-white mb-safe"
      onClick={onClick}
    >
      <div className="flex flex-col justify-center items-center w-16 h-16 rounded-full bg-sprinkle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </div>
    </div>
  );
};
