import React, { useRef, useState } from "react";

type Logo = { id: string; url: string };

export const SelectLogoModal: React.FC<{
  logos: Logo[];
  onSelected: (logoId?: string, logoUrl?: string, logoBase64String?: string) => void;
  onClose: () => void;
}> = ({ logos, onSelected, onClose }) => {
  function textSourceLogoChanged(e: any) {
    var files = e.target.files;

    if (files.length === 1) {
      var file = files[0];
      var reader = new FileReader();
      reader.addEventListener("load", (event: any) => {
        // result has form //data:image/jpg;base64,<base64 data>
        setLogoBase64String(event.target.result);
        setSelectedLogoId(undefined);
        setSelectedLogoUrl(undefined);
      });
      reader.readAsDataURL(file);
    }
  }

  function openFileDialog() {
    if (logoRef !== null && logoRef.current !== null) {
      (logoRef.current as any).click();
    }
  }

  const [selectedLogoId, setSelectedLogoId] = useState<string>();
  const [selectedLogoUrl, setSelectedLogoUrl] = useState<string>();
  const [logoBase64String, setLogoBase64String] = useState<string>();
  const logoRef = useRef(null);

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 h-1/2 flex flex-col space-y-4 bg-transparent rounded-md z-50">
      <div className="bg-white p-4 border-2 overflow-y-scroll">
        <div className="flex flex-col items-center space-y-8">
          <button
            className={`w-32 h-32 text-gray-400 border rounded-full ${
              logoBase64String !== undefined
                ? "ring ring-sprinkle border-2 border-white"
                : "border-2 border-gray-100"
            }`}
            onClick={() => {
              logoBase64String === undefined ? openFileDialog() : setLogoBase64String(undefined);
            }}
          >
            {logoBase64String !== undefined ? (
              <img className="w-32 h-32 rounded-full" src={logoBase64String} alt="Logo" />
            ) : (
              <p>
                Neues
                <br />
                Logo
              </p>
            )}
          </button>
          <div className="w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-16">
            {logos.map((logo) => (
              <button
                key={logo.id}
                className={`flex flex-col items-center justify-center w-24 h-24 rounded-full ${
                  logo.id === selectedLogoId
                    ? "ring ring-sprinkle border-2 border-white"
                    : "border-2 border-gray-100"
                }`}
                onClick={() => {
                  setSelectedLogoId(logo.id);
                  setSelectedLogoUrl(logo.url);
                  setLogoBase64String(undefined);
                }}
              >
                <img className="w-24 h-24 rounded-full" src={logo.url} alt="Logo" />
              </button>
            ))}
          </div>
          <input ref={logoRef} className="hidden" type="file" onChange={textSourceLogoChanged} />
        </div>
      </div>
      <div className="w-full flex flex-row justify-end space-x-4">
        <button
          className="border-2 border-gray-300 rounded-md bg-white text-dark px-2 py-2"
          onClick={() => onClose()}
        >
          Abbrechen
        </button>
        {(logoBase64String !== undefined || selectedLogoId !== undefined) && (
          <button
            className="border-2 border-gray-300 rounded-md bg-gray-100 text-dark px-2 py-2"
            onClick={() => {
              onSelected(selectedLogoId, selectedLogoUrl, logoBase64String);
              onClose();
            }}
          >
            Ok
          </button>
        )}
      </div>
    </div>
  );
};
