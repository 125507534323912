import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CreateSprinkle } from "./pages/CreateSprinkle";
import { Home } from "./pages/Home";
import { Sprinkles } from "./pages/Sprinkles";
import { URL } from "./urls";
import client from "./client";
import { Wrapper } from "@googlemaps/react-wrapper";
import { EditSprinkle } from "./pages/EditSprinkle";
import { LoginState } from "./state";
import { RegisterUser } from "./pages/RegisterUser";

function App() {
  async function login(email: string, password: string) {
    try {
      await client.authenticate({
        strategy: "local",
        email: email,
        password: password,
      });

      const { user } = await client.get("authentication");
      setLoginState({ user: user, errorMsg: "" });
    } catch {
      setLoginState({
        user: undefined,
        errorMsg: "E-Mail oder Passwort ungültig",
      });
    }
  }

  async function logout() {
    await client.logout();
    setLoginState({ user: undefined, errorMsg: "" });
  }

  const [loginState, setLoginState] = useState<LoginState>({
    user: undefined,
    errorMsg: "",
  });

  useEffect(() => {
    async function reAuthenticate() {
      try {
        await client.reAuthenticate();
        const { user } = await client.get("authentication");
        setLoginState({ user: user, errorMsg: "" });
      } catch {
        setLoginState({ user: undefined, errorMsg: "" });
      }
    }

    reAuthenticate();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home loginState={loginState} login={login} logout={logout} />} />
        <Route
          path={`${URL.createSprinkle}/*`}
          element={<CreateSprinkle loginState={loginState} />}
        />
        <Route path={`${URL.editSprinkle}/:poiId/*`} element={<EditSprinkle />} />
        <Route
          path={URL.sprinkles}
          element={
            <Wrapper apiKey="AIzaSyBCYb6XFxcZ_QXw8t6vUbZUCMG9kD0PFlM">
              <Sprinkles loginState={loginState} />
            </Wrapper>
          }
        />
        <Route path={URL.registerUser} element={<RegisterUser />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
