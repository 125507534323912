import React from "react";

export const StepCircle: React.FC<{
  image: React.FC<{ className?: string }>;
  imageWidth: string;
  fill: boolean;
  active: boolean;
}> = ({ image, imageWidth, fill, active }) => {
  const [bgColor, textColor, border] = active
    ? ["bg-white", "text-sprinkle", "border-4 border-sprinkle"]
    : ["bg-sprinkle", "text-white", "border-4 border-dark"];
  const styles = `flex flex-col items-center justify-center w-10 h-10 ${bgColor} rounded-full ${border}`;

  return (
    <div className={styles}>
      {image({
        className: `${imageWidth} ${
          fill ? "fill-current " + textColor + " " : ""
        } stroke-current ${textColor}`,
      })}
    </div>
  );
};
