import React from "react";
import { CloseIcon } from "./CloseIcon";

export const CreateLocationInfoModal: React.FC<{
  hidden: boolean;
  onClick: React.MouseEventHandler;
}> = ({ hidden, onClick }) => {
  return (
    <div
      className={`w-full flex flex-col h-56 bg-dark items-center rounded-t-3xl text-white space-y-2 p-4 ${
        hidden ? "hidden" : ""
      }`}
    >
      <div className="self-end cursor-pointer" onClick={onClick}>
        <CloseIcon className="w-6 fill-current text-white" />
      </div>
      <p className="text-center">
        <span className="font-bold">
          Setze deinen Sprinkle auf einen Verkehrsweg
        </span>
        <br />
        (mit Sicht auf das Objekt)
      </p>
      <p className="text-center">
        Im <span className="font-bold">Radius von 100 m</span> wird dein
        Sprinkle <br />
        automatisch abgespielt
      </p>
    </div>
  );
};
