import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Action, State } from "../state";
import client from "../client";
import { Poi } from "../types";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAYY2AWQBSEJT5YHOR",
  secretAccessKey: "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",
  region: "eu-central-1",
});

type Data = Partial<{
  title: string;
  text: string;
  langCodeIso6391: string;
  picture: { mimeType: string; dataBase64: string };
  pictureSourceText: string;
  pictureSourceUrl: string;
  geofenceCenter: { lat: number; long: number };
  textSourceLogo: { mimeType: string; dataBase64: string } | string;
  textSourceText: string;
  textSourceUrl: string;
}>;

export const UpdateSprinkle: React.FC<{
  dispatch: React.Dispatch<Action>;
  state: State;
  currentPoi: Poi | undefined;
}> = ({ dispatch, state, currentPoi }) => {
  const [uploadState, setUploadState] = useState<{
    uploaded: boolean;
    errorMsg: string;
  }>({
    uploaded: false,
    errorMsg: "",
  });

  useEffect(() => {
    async function patch(currentPoi: Poi) {
      try {
        const data: Data = {
          ...(currentPoi.title !== state.title && { title: state.title }),
          ...(currentPoi.text !== state.text && { text: state.text }),
          ...(currentPoi.langCodeIso6391 !== state.langCodeIso6391 && {
            langCodeIso6391: state.langCodeIso6391,
          }),
          ...(state.pictureChanged &&
            state.pictureBase64String !== undefined && {
              picture: {
                mimeType: state.pictureBase64String.split(";")[0].split(":")[1], // data:image/jpg;base64,<base64 data>
                dataBase64: state.pictureBase64String.split(";")[1].split(",")[1], // data:image/jpg;base64,<base64 data>
              },
            }),
          ...(currentPoi.pictureSourceText !== state.pictureSourceText && {
            pictureSourceText: state.pictureSourceText,
          }),
          ...(currentPoi.pictureSourceUrl !== state.pictureSourceUrl && {
            pictureSourceUrl: state.pictureSourceUrl,
          }),
          ...(state.geofenceCenter !== undefined &&
            (currentPoi.geofenceCenter.lat !== state.geofenceCenter.lat() ||
              currentPoi.geofenceCenter.long !== state.geofenceCenter.lng()) && {
              geofenceCenter: { lat: state.geofenceCenter.lat(), long: state.geofenceCenter.lng() },
            }),
          ...(state.textSourceLogoChanged &&
            state.textSourceLogoId !== undefined &&
            currentPoi.textSourceLogoId !== state.textSourceLogoId && {
              textSourceLogo: state.textSourceLogoId,
            }),
          ...(state.textSourceLogoChanged &&
            state.textSourceLogoBase64String !== undefined && {
              textSourceLogo: {
                mimeType: state.textSourceLogoBase64String.split(";")[0].split(":")[1], // data:image/jpg;base64,<base64 data>
                dataBase64: state.textSourceLogoBase64String.split(";")[1].split(",")[1], // data:image/jpg;base64,<base64 data>},
              },
            }),
          ...(currentPoi.textSourceText !== state.textSourceText && {
            textSourceText: state.textSourceText,
          }),
          ...(currentPoi.textSourceUrl !== state.textSourceUrl && {
            textSourceUrl: state.textSourceUrl,
          }),
        };

        const service = client.service("pois");
        await service.patch(currentPoi.id, data);
        setUploadState({ uploaded: true, errorMsg: "" });
        dispatch({ id: "resetState", data: undefined });
      } catch (error: any) {
        setUploadState({
          uploaded: false,
          errorMsg: `Fehler beim Aktualisieren deines Sprinkles: ${error.message}`,
        });
      }
    }

    if (!uploadState.uploaded && currentPoi !== undefined) {
      patch(currentPoi);
    }
  }, [currentPoi, state, uploadState.uploaded, dispatch]);

  const content = (
    <div className="flex flex-col h-full justify-start items-center pt-12 bg-dark bg-center bg-no-repeat bg-congrats bg-contain">
      <div className="text-white text-center">
        {uploadState.uploaded && <p>Sprinkle aktualisiert</p>}
        {!uploadState.uploaded && uploadState.errorMsg === "" && (
          <p>Dein Sprinkle wird aktualisiert ...</p>
        )}
        {!uploadState.uploaded && uploadState.errorMsg !== "" && <p>{uploadState.errorMsg}</p>}
      </div>
    </div>
  );

  if (uploadState.uploaded || uploadState.errorMsg !== "") {
    return <Link to="/">{content}</Link>;
  }
  return content;
};
