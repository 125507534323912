/*global google*/

type User = {
  id: number;
  email: string;
  name: string;
  sex: string | null;
  avatar: number | null;
  curator: boolean;
};

export type LoginState = { user: User | undefined; errorMsg: string };

export type State = {
  inputDataFormat?: "audio" | "text";
  title?: string;
  text?: string;
  textSourceLogoId?: string;
  textSourceLogoBase64String?: string;
  textSourceLogoChanged: boolean;
  textSourceText?: string;
  textSourceUrl?: string;
  langCodeIso6391?: string;
  pictureBase64String?: string;
  pictureChanged: boolean;
  pictureSourceText?: string;
  pictureSourceUrl?: string;
  geofenceCenter?: google.maps.LatLng;
};

export type Action = {
  id:
    | "init"
    | "setInputDataFormat"
    | "setTitle"
    | "setText"
    | "setTextSourceLogo"
    | "setTextSourceText"
    | "setTextSourceUrl"
    | "setPicture"
    | "setPictureSourceText"
    | "setPictureSourceUrl"
    | "setGeofenceCenter"
    | "resetState";
  data: any;
};

export function reducer(state: State, action: Action): State {
  switch (action.id) {
    case "init":
      return action.data.state;
    case "setInputDataFormat":
      return { ...state, inputDataFormat: action.data };
    case "setTitle":
      return { ...state, title: action.data.title };
    case "setText":
      return { ...state, text: action.data.text };
    case "setTextSourceLogo":
      if ("textSourceLogoId" in action.data) {
        return {
          ...state,
          textSourceLogoId: action.data.textSourceLogoId,
          textSourceLogoBase64String: undefined,
          textSourceLogoChanged: true,
        };
      } else if ("textSourceLogoBase64String" in action.data) {
        return {
          ...state,
          textSourceLogoBase64String: action.data.textSourceLogoBase64String,
          textSourceLogoId: undefined,
          textSourceLogoChanged: true,
        };
      }
      return state;
    case "setTextSourceText":
      return {
        ...state,
        textSourceText: action.data.textSourceText,
      };
    case "setTextSourceUrl":
      return {
        ...state,
        textSourceUrl: action.data.textSourceUrl,
      };
    case "setPicture":
      return {
        ...state,
        pictureBase64String: action.data.pictureBase64String,
        pictureChanged: true,
      };
    case "setPictureSourceText":
      return {
        ...state,
        pictureSourceText: action.data.pictureSourceText,
      };
    case "setPictureSourceUrl":
      return {
        ...state,
        pictureSourceUrl: action.data.pictureSourceUrl,
      };
    case "setGeofenceCenter":
      return { ...state, geofenceCenter: action.data.geofenceCenter };
    case "resetState":
      return {
        langCodeIso6391: "de",
        pictureChanged: false,
        textSourceLogoChanged: false,
      };
    default:
      throw new Error("Unknown action");
  }
}
