/*global google*/

import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { URL } from "../urls";
import moment from "moment";
import client from "../client";
import { Marker } from "../components/Marker";
import { CloseIcon } from "../components/CloseIcon";
import { Poi, PoiFind } from "../types";
import { LoginState } from "../state";

const zug = { lat: 47.173371, lng: 8.516037 };

export const Sprinkles: React.FC<{ loginState: LoginState }> = ({ loginState }) => {
  async function loadAudio(poiId: number) {
    const service = client.service("pois");
    const poi: Poi = await service.get(poiId);

    AWS.config.update({
      accessKeyId: "AKIAYY2AWQBSEJT5YHOR",
      secretAccessKey: "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",
      region: "eu-central-1",
    });

    const s3 = new AWS.S3();

    try {
      const audioUrl = await s3.getSignedUrlPromise("getObject", {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: poi.audioS3Key,
        Expires: 300,
      });

      const pictureUrl = await s3.getSignedUrlPromise("getObject", {
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: poi.pictureS3Key,
        Expires: 300,
      });

      setAudioUrl(audioUrl);
      setPictureUrl(pictureUrl);
    } catch (error: any) {
      console.debug("Error gettings files from S3");
      return;
    }
  }

  async function deleteSprinkle(poiId: number) {
    try {
      const service = client.service("pois");
      await service.remove(poiId);
    } catch {
      // TODO
    }
  }

  const [sprinkles, setSprinkles] = useState<PoiFind[]>([]);
  const [audioUrl, setAudioUrl] = useState<string>();
  const [pictureUrl, setPictureUrl] = useState<string>();
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadSprinkles() {
      const service = client.service("pois");
      try {
        const sprinkles = await service.find({ query: { lang: "de" } });
        setSprinkles(sprinkles);
      } catch {
        // TODO
      }
    }

    loadSprinkles();
  }, []);

  useEffect(() => {
    if (mapRef.current && !map) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: zug,
        zoom: 10,
        disableDefaultUI: true,
        draggableCursor: "default",
      });

      setMap(map);
    }
  }, [mapRef, map]);

  useEffect(() => {
    client.service("pois").on("removed", (sprinkle: { id: string }) => {
      setSprinkles((sprinkles) => sprinkles.filter((s) => s.id !== parseInt(sprinkle.id)));
    });
  }, []);

  return (
    <div className="p-8">
      <Link to="/" className="flex flex-row justify-end">
        <CloseIcon className="w-6 fill-current text-white" />
      </Link>
      <div className="w-[1000px] h-[800px] bg-dark mx-auto" ref={mapRef}>
        {sprinkles.map((s) => (
          <Marker
            key={s.id}
            label={s.title}
            position={{ lat: s.geofenceCenter.lat, lng: s.geofenceCenter.long }}
            map={map}
          />
        ))}
      </div>
      <div className="container mt-8 mx-auto bg-gray-100/25 p-4">
        <ul className="flex flex-col space-y-4">
          {sprinkles
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((s) => (
              <li key={s.id} className="flex flex-row space-x-4">
                <span>{moment(s.createdAt).format("DD.MM.YYYY")}</span>
                <span>{s.title}</span>
                <button className="border rounded-md" onClick={() => loadAudio(s.id)}>
                  Audio
                </button>
                <button
                  className="border rounded-md"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${s.geofenceCenter.lat},${s.geofenceCenter.long}`
                    )
                  }
                >
                  Karte
                </button>
                {loginState.user !== undefined && (
                  <button
                    className="border rounded-md"
                    onClick={() => navigate(`${URL.editSprinkle}/${s.id}`)}
                  >
                    Editieren
                  </button>
                )}
                {loginState.user !== undefined && (
                  <button className="border rounded-md" onClick={() => deleteSprinkle(s.id)}>
                    Löschen
                  </button>
                )}
              </li>
            ))}
        </ul>
      </div>
      {audioUrl !== undefined && pictureUrl !== undefined && (
        <div className="flex flex-col items-center justify-center space-y-8 p-4 w-auto h-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-100 rounded-md shadow-md">
          <img src={pictureUrl} width="400px" alt="Bild" />
          <audio controls>
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <button
            className="p-2 bg-gray-400 rounded-md text-white"
            onClick={() => {
              setAudioUrl(undefined);
              setPictureUrl(undefined);
            }}
          >
            Schliessen
          </button>
        </div>
      )}
    </div>
  );
};
