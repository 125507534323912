/*global google*/

import React from "react";
import { useEffect, useRef, useState } from "react";
import { Action, State } from "../state";
import { CreateLocationInfoModal } from "./CreateLocationInfoModal";
import { UploadButton } from "./UploadButton";
import { Marker } from "./Marker";
import locationIcon from "../images/location.png";
import { CenterLocationButton } from "./CenterLocationButton";

const centerOfSwitzerland = { lat: 46.801111, lng: 8.226667 };

export const CreateLocation: React.FC<{
  dispatch: React.Dispatch<Action>;
  state: State;
}> = ({ dispatch, state }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [showInfo, setShowInfo] = useState(true);
  // eslint-disable-next-line no-undef
  const [location, setLocation] = useState<GeolocationCoordinates>();

  function centerCurrentLocation() {
    if (map !== undefined && location !== undefined) {
      map.setCenter({ lat: location.latitude, lng: location.longitude });
    }
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(position.coords);
        },
        null,
        { enableHighAccuracy: true }
      );
    }
  }, []);

  useEffect(() => {
    function onClick(e: google.maps.MapMouseEvent) {
      dispatch({
        id: "setGeofenceCenter",
        data: { geofenceCenter: e.latLng },
      });
    }

    if (mapRef.current && !map) {
      const center = state.geofenceCenter
        ? { lat: state.geofenceCenter.lat(), lng: state.geofenceCenter.lng() }
        : centerOfSwitzerland;

      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 8,
        disableDefaultUI: true,
        draggableCursor: "default",
      });

      google.maps.event.clearListeners(map, "click");
      map.addListener("click", onClick);
      setMap(map);
    }
  }, [dispatch, map, state.geofenceCenter]);

  return (
    <>
      <div className="w-full h-full" ref={mapRef} />
      {location !== undefined && (
        <Marker
          icon={locationIcon}
          position={{ lat: location.latitude, lng: location.longitude }}
          map={map}
        />
      )}
      {state.geofenceCenter !== undefined && <Marker position={state.geofenceCenter} map={map} />}
      <div className="absolute bottom-0 w-full flex flex-col">
        <div className="pr-8 pb-8 self-end">
          <CenterLocationButton onClick={centerCurrentLocation} />
        </div>
        <CreateLocationInfoModal
          hidden={!showInfo || state.geofenceCenter !== undefined}
          onClick={() => setShowInfo(false)}
        />
      </div>
      {state.geofenceCenter !== undefined && <UploadButton to="../congrats" />}
    </>
  );
};
