import React from "react";

export const TextIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 12H0V14H10V12ZM12.5 4H0V6H12.5V4ZM0 10H16V8H0V10ZM0 0V2H16V0H0Z" />
    </svg>
  );
};
