/*global google*/

import React from "react";
import { useEffect, useState } from "react";

export const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const { map, label } = options;

  useEffect(() => {
    if (marker === undefined) {
      const newMarker = new google.maps.Marker();
      const infoWindow = new google.maps.InfoWindow({
        content: `<span style="color: black">${label as string}</span>`,
      });
      newMarker.addListener("click", () => {
        infoWindow.open(map, newMarker);
      });
      setMarker(newMarker);
    }

    return () => {
      if (marker) {
        // Remove marker
        marker.setMap(null);
      }
    };
  }, [label, map, marker, options]);

  useEffect(() => {
    if (marker !== undefined) {
      marker.setOptions(options);
      marker.setLabel("");
    }
  }, [marker, options]);

  return null;
};
