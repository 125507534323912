import React from "react";
import { Link } from "react-router-dom";

export const Continue: React.FC<{
  to: string;
  visible: boolean;
}> = ({ to, visible }) => {
  return (
    <Link to={to} className={visible ? "visible" : "invisible"}>
      <div className="flex flex-col justify-center items-center w-14 h-14 bg-sprinkle rounded-full">
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.569697 14.5697L2 16L10 8L2 -6.99382e-07L0.569698 1.4303L7.13939 8L0.569697 14.5697Z"
            fill="white"
          />
        </svg>
      </div>
    </Link>
  );
};
