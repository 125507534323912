import React from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseIcon } from "./CloseIcon";
import { OptionalLink } from "./OptionalLink";
import { StepCircle } from "./StepCircle";
import { CameraIcon } from "../components/CameraIcon";
import { LocationIcon } from "../components/LocationIcon";
import { TextIcon } from "../components/TextIcon";
import { State } from "../state";

export const CreateSprinkleNav: React.FC<{
  state: State;
}> = ({ state }) => {
  function canCreatePicture(): boolean {
    return state.title !== undefined && state.text !== undefined;
  }

  function canCreateLocation(): boolean {
    return canCreatePicture() && state.pictureBase64String !== undefined;
  }

  const location = useLocation();

  return (
    <div className="grid grid-cols-3 bg-dark p-4 mt-safe">
      <div></div>
      <div className="flex flex-row items-center justify-center space-x-8">
        <Link to="text">
          <StepCircle
            image={TextIcon}
            imageWidth="w-4"
            fill={true}
            active={location.pathname.endsWith("text")}
          />
        </Link>
        <OptionalLink to={canCreatePicture() ? "picture" : undefined}>
          <StepCircle
            image={CameraIcon}
            imageWidth="w-4"
            fill={false}
            active={location.pathname.endsWith("picture")}
          />
        </OptionalLink>
        <OptionalLink to={canCreateLocation() ? "location" : undefined}>
          <StepCircle
            image={LocationIcon}
            imageWidth="w-4"
            fill={false}
            active={location.pathname.endsWith("location")}
          />
        </OptionalLink>
      </div>
      <div className="flex flex-row items-center justify-self-end">
        <Link to="/">
          <CloseIcon className="w-6 fill-current text-white" />
        </Link>
      </div>
    </div>
  );
};
