import React from "react";

export const CenterLocationIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="currentColor" />
      <path
        d="M8 20.1089L29.3333 9.33594L18.5596 30.6693L15.367 23.3017L8 20.1089Z"
        fill="#121222"
      />
    </svg>
  );
};
