import { bool } from "aws-sdk/clients/signer";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import client from "../client";
import { CloseIcon } from "../components/CloseIcon";

export const RegisterUser: React.FC = () => {
  async function registerUser() {
    setUserRegistered(false);
    setError("");
    if (checkInput()) {
      try {
        let fileId = null;

        if (avatar !== undefined) {
          const fileService = client.service("file");
          const file = await fileService.create({
            title: "Avatar",
            dataBase64: avatar,
            category: "avatar",
          });

          fileId = file.id;
        }

        const userService = client.service("users");
        try {
          await userService.create({
            email: email,
            password: password,
            name: name,
            avatar: fileId,
          });
        } catch {
          if (fileId !== null) {
            const fileService = client.service("file");
            await fileService.remove(fileId);
          }

          throw new Error("Es ist ein Fehler aufgetreten! Versuche eine andere E-Mail-Adresse");
        }
        setEmail("");
        setPassword("");
        setName("");
        setAvatar(undefined);
        setUserRegistered(true);
      } catch {
        setError("Es ist ein unerwarteter Fehler aufgetreten!");
      }
    } else {
      setError("Alle Felder korrekt ausfüllen!");
    }
  }

  function checkInput(): bool {
    return (
      email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/) != null &&
      password !== "" &&
      name !== ""
    );
  }

  function openPicture() {
    if (inputRef !== null && inputRef.current !== null) {
      (inputRef.current as any).click();
    }
  }

  function loadPicture(e: any) {
    var files = e.target.files;

    if (files.length === 1) {
      var file = files[0];
      var reader = new FileReader();
      reader.addEventListener("load", (event: any) => {
        // result has form //data:image/jpg;base64,<base64 data>
        setAvatar(event.target.result);
      });
      reader.readAsDataURL(file);
    }
  }

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [avatar, setAvatar] = useState<string>();
  const [userRegistered, setUserRegistered] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const inputValid = checkInput();

  return (
    <div className="p-8">
      <Link to="/" className="flex flex-row justify-end">
        <CloseIcon className="w-6 fill-current text-white" />
      </Link>
      <div className="flex flex-col space-y-4 items-center">
        <input
          className="text-dark"
          type="text"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setUserRegistered(false);
            setError("");
          }}
        />
        <input
          className="text-dark"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setUserRegistered(false);
            setError("");
          }}
        />
        <input
          className="text-dark"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setUserRegistered(false);
            setError("");
          }}
        />
        <div className="flex flex-row">
          <button className="w-32 h-32 text-gray-400 border rounded-full" onClick={openPicture}>
            {avatar !== undefined ? (
              <img className="w-32 h-32 rounded-full" src={avatar} alt="Logo" />
            ) : (
              <p>Avatar</p>
            )}
          </button>
          <button
            className="w-6 h-6 rounded-full border text-sm text-gray-400 self-end"
            onClick={() => {
              setAvatar(undefined);
            }}
          >
            x
          </button>
        </div>
        <button
          className={`p-2 border rounded-md ${
            inputValid ? "text-white border-white" : "text-gray-600 border-gray-600"
          }`}
          disabled={!inputValid}
          onClick={() => registerUser()}
        >
          Registrieren
        </button>

        {userRegistered && (
          <span className="text-green-600 font-bold">Benutzer erfolgreich registriert!</span>
        )}
        {error !== "" && <span className="text-red-400 font-bold">{error}</span>}
      </div>
      <input ref={inputRef} type="file" className="hidden" onChange={loadPicture} />
    </div>
  );
};
