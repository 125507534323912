import React from "react";
import { Continue } from "./Continue";
import { TakePictureButton } from "./TakePictureButton";
import { Action, State } from "../state";
import { useRef } from "react";

export const CreatePicture: React.FC<{
  dispatch: React.Dispatch<Action>;
  state: State;
}> = ({ dispatch, state }) => {
  function openPicture() {
    if (inputRef !== null && inputRef.current !== null) {
      (inputRef.current as any).click();
    }
  }

  function loadPicture(e: any) {
    var files = e.target.files;

    if (files.length === 1) {
      var file = files[0];
      var reader = new FileReader();
      reader.addEventListener("load", (event: any) => {
        // result has form //data:image/jpg;base64,<base64 data>
        dispatch({ id: "setPicture", data: { pictureBase64String: event.target.result } });
      });
      reader.readAsDataURL(file);
    }
  }

  function pictureSourceTextChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      id: "setPictureSourceText",
      data: { pictureSourceText: event.target.value },
    });
  }

  function pictureSourceUrlChanged(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      id: "setPictureSourceUrl",
      data: { pictureSourceUrl: event.target.value },
    });
  }

  const inputRef = useRef(null);

  return (
    <div
      className={`flex flex-col items-center space-y-8 bg-dark grow text-white ${
        state.pictureBase64String === undefined
          ? "bg-create-picture bg-contain bg-no-repeat bg-center"
          : ""
      }`}
    >
      {state.pictureBase64String === undefined && (
        <p className="absolute bottom-32 text-center">
          <span>Füge deinem Sprinkle</span>
          <br />
          <span className="font-bold">ein Bild hinzu</span>
        </p>
      )}
      {state.pictureBase64String !== undefined && (
        <>
          <img className="h-1/2" src={state.pictureBase64String} alt="Bild" />

          <input
            className="w-1/2 bg-light-gray text-dark placeholder-normal-gray border-0 focus:ring-0 rounded-md"
            type="text"
            placeholder="Quelle Text - optional"
            maxLength={50}
            value={state.pictureSourceText}
            onChange={pictureSourceTextChanged}
          />
          <input
            className="w-1/2 bg-light-gray text-dark placeholder-normal-gray border-0 focus:ring-0 rounded-md"
            type="text"
            placeholder="Quelle URL - optional"
            maxLength={2048}
            value={state.pictureSourceUrl}
            onChange={pictureSourceUrlChanged}
          />
        </>
      )}
      <div className="absolute -bottom-4 w-full flex flex-row items-center justify-center space-x-10">
        <Continue to="" visible={false} />
        <TakePictureButton onClick={openPicture} />
        <Continue to="../location" visible={state.pictureBase64String !== undefined} />
      </div>
      <input ref={inputRef} type="file" className="hidden" onChange={loadPicture} />
    </div>
  );
};
