import React from "react";

export const CloseIcon: React.FC<{ className: string }> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="7.07104" y="9.19238" width="3" height="23" transform="rotate(-45 7.07104 9.19238)" />
      <rect
        x="9.19238"
        y="25.4561"
        width="3"
        height="23"
        transform="rotate(-135 9.19238 25.4561)"
      />
    </svg>
  );
};
