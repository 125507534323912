import React from "react";
import { CenterLocationIcon } from "./CenterLocationIcon";

export const CenterLocationButton: React.FC<{
  onClick: React.MouseEventHandler;
}> = ({ onClick }) => {
  return (
    <div
      className="flex flex-col justify-center items-center w-12 h-12 rounded-full cursor-pointer z-10"
      onClick={onClick}
    >
      <CenterLocationIcon className="fill-current text-white" />
    </div>
  );
};
