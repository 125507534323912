/*global google*/

import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useReducer } from "react";
import { reducer } from "../state";
import { CreateText } from "../components/CreateText";
import { CreateSprinkleFrame } from "../components/CreateSprinkleFrame";
import { CreatePicture } from "../components/CreatePicture";
import { Wrapper } from "@googlemaps/react-wrapper";
import { CreateLocation } from "../components/CreateLocation";
import { URL } from "../urls";
import client from "../client";
import { Poi } from "../types";
import AWS from "aws-sdk";
import { UpdateSprinkle } from "../components/UpdateSprinkle";

AWS.config.update({
  accessKeyId: "AKIAYY2AWQBSEJT5YHOR",
  secretAccessKey: "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",
  region: "eu-central-1",
});

async function readBlobAsBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (event: any) => {
      resolve(event.target.result);
    });
    fileReader.readAsDataURL(blob);
  });
}

export const EditSprinkle: React.FC = () => {
  const [currentPoi, setCurrentPoi] = useState<Poi>();
  const [state, dispatch] = useReducer(reducer, {
    langCodeIso6391: "de",
    pictureChanged: false,
    textSourceLogoChanged: false,
  });
  const poiIdString = useParams().poiId;
  const poiId: number | undefined = poiIdString !== undefined ? parseInt(poiIdString) : undefined;

  useEffect(() => {
    async function loadSprinkle(poiId: number) {
      try {
        const service = client.service("pois");
        const poi: Poi = await service.get(poiId);
        const s3 = new AWS.S3();
        const pictureUrl = await s3.getSignedUrlPromise("getObject", {
          Bucket: process.env.REACT_APP_S3_BUCKET,
          Key: poi.pictureS3Key,
          Expires: 300,
        });
        const pictureResponse = await fetch(pictureUrl);
        const pictureBlob = await pictureResponse.blob();
        let textSourceLogoBase64String: string | undefined = undefined;

        if (poi.textSourceLogoS3Key !== null) {
          const logoUrl = await s3.getSignedUrlPromise("getObject", {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: poi.textSourceLogoS3Key,
            Expires: 300,
          });

          const logoResponse = await fetch(logoUrl);
          const logoBlob = await logoResponse.blob();
          textSourceLogoBase64String = await readBlobAsBase64(logoBlob);
        }

        const pictureBase64String = await readBlobAsBase64(pictureBlob);

        setCurrentPoi(poi);
        dispatch({
          id: "init",
          data: {
            state: {
              inputDataFormat: "text",
              title: poi.title,
              text: poi.text,
              textSourceLogoId: poi.textSourceLogoId,
              textSourceLogoBase64String: textSourceLogoBase64String,
              textSourceLogoChanged: false,
              textSourceText: poi.textSourceText !== null ? poi.textSourceText : undefined,
              textSourceUrl: poi.textSourceUrl !== null ? poi.textSourceUrl : undefined,
              langCodeIso6391: poi.langCodeIso6391,
              pictureBase64String: pictureBase64String,
              pictureChanged: false,
              pictureSourceText: poi.pictureSourceText !== null ? poi.pictureSourceText : undefined,
              pictureSourceUrl: poi.pictureSourceUrl !== null ? poi.pictureSourceUrl : undefined,
              geofenceCenter: new google.maps.LatLng(
                poi.geofenceCenter.lat,
                poi.geofenceCenter.long
              ),
            },
          },
        });
      } catch (error: any) {
        console.debug(error.message);
      }
    }

    if (poiId !== undefined) {
      loadSprinkle(poiId);
    }
  }, [poiId]);

  return (
    <Routes>
      <Route
        path="congrats"
        element={<UpdateSprinkle state={state} currentPoi={currentPoi} dispatch={dispatch} />}
      />
      <Route path="*" element={<CreateSprinkleFrame state={state} />}>
        <Route path="" element={<Navigate to={`${URL.editSprinkle}/${poiId}/text`} />} />
        <Route path="text" element={<CreateText dispatch={dispatch} state={state} />} />
        <Route path="picture" element={<CreatePicture dispatch={dispatch} state={state} />} />
        <Route
          path="location"
          element={
            <Wrapper apiKey="AIzaSyBCYb6XFxcZ_QXw8t6vUbZUCMG9kD0PFlM">
              <CreateLocation dispatch={dispatch} state={state} />
            </Wrapper>
          }
        />
      </Route>
    </Routes>
  );
};
