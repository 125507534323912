import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../urls";
import sprinkle from "../images/sprinkle.png";
import { LoginState } from "../state";

export const Home: React.FC<{
  loginState: LoginState;
  login: (email: string, password: string) => void;
  logout: () => void;
}> = ({ loginState, login, logout }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  function closeLoginModal() {
    setShowLoginModal(false);
    setEmail("");
    setPassword("");
  }

  useEffect(() => {
    if (loginState.user !== undefined) {
      closeLoginModal();
    }
  }, [loginState]);

  return (
    <div className="w-full h-full flex flex-col items-center pt-8 bg-dark">
      {loginState.user !== undefined && (
        <div className="flex flex-row self-end items-center space-x-4 mr-16">
          <span className="font-bold">{loginState.user.name}</span>
          <button className="bg-white text-dark font-bold rounded-md p-2" onClick={() => logout()}>
            Logout
          </button>
        </div>
      )}
      {loginState.user === undefined && (
        <button
          className="self-end mr-16 bg-white text-dark font-bold rounded-md p-2"
          onClick={() => setShowLoginModal(true)}
        >
          Login
        </button>
      )}
      <img src={sprinkle} alt="Sprinkle" />
      <div className="flex flex-row space-x-4">
        <button
          className="bg-sprinkle text-white font-bold rounded-md p-2"
          onClick={() => navigate(URL.createSprinkle)}
        >
          Sprinkle erstellen
        </button>
        <button
          className="bg-sprinkle text-white font-bold rounded-md p-2"
          onClick={() => navigate(URL.sprinkles)}
        >
          Sprinkles
        </button>
        <button
          className="bg-sprinkle text-white font-bold rounded-md p-2"
          onClick={() => navigate(URL.registerUser)}
        >
          Benutzer registrieren
        </button>
      </div>
      {showLoginModal && (
        <div className="absolute p-8 mx-auto flex flex-col items-center justify-center space-y-8 bg-gray-100 rounded-md">
          <input
            className="text-dark"
            type="text"
            placeholder="E-Mail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="text-dark"
            type="password"
            placeholder="Passwort"
            onChange={(e) => setPassword(e.target.value)}
          />
          {loginState.errorMsg !== "" && <p className="text-sprinkle">{loginState.errorMsg}</p>}
          <div className="flex flex-row w-full items-center justify-end space-x-4">
            <span className="text-dark cursor-pointer" onClick={() => closeLoginModal()}>
              Abbrechen
            </span>
            <button className="px-4 py-2 bg-dark" onClick={() => login(email, password)}>
              Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
