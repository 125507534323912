import React from "react";
import { Outlet } from "react-router-dom";
import { State } from "../state";
import { CreateSprinkleNav } from "./CreateSprinkleNav";

export const CreateSprinkleFrame: React.FC<{ state: State }> = ({ state }) => {
  return (
    <div className="w-full h-full">
      <div className="flex flex-col h-full justify-start">
        <CreateSprinkleNav state={state} />
        <Outlet />
      </div>
    </div>
  );
};
