import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";

const client = feathers();
const restClient = rest(process.env.REACT_APP_API_BASE_URL);
client.configure(restClient.fetch(window.fetch.bind(window)));
client.configure(auth({ storageKey: "sprinkle_session" }));

export default client;
