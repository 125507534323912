import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Action, LoginState, State } from "../state";
import client from "../client";

type Data = {
  title: string;
  text: string;
  langCodeIso6391: string;
  picture: { mimeType: string; dataBase64: string };
  pictureSourceText?: string;
  pictureSourceUrl?: string;
  geofenceCenter: { lat: number; long: number };
  textSourceLogo?: { mimeType: string; dataBase64: string } | string;
  textSourceText?: string;
  textSourceUrl?: string;
  createdById?: number;
  curated: boolean;
};

export const Congrats: React.FC<{
  state: State;
  loginState: LoginState;
  dispatch: React.Dispatch<Action>;
}> = ({ state, loginState, dispatch }) => {
  const [uploadState, setUploadState] = useState<{
    uploaded: boolean;
    errorMsg: string;
  }>({
    uploaded: false,
    errorMsg: "",
  });

  useEffect(() => {
    async function upload(data: Data) {
      try {
        const service = client.service("pois");
        await service.create(data);
        setUploadState({ uploaded: true, errorMsg: "" });
        dispatch({ id: "resetState", data: undefined });
      } catch (error: any) {
        setUploadState({
          uploaded: false,
          errorMsg: `Fehler beim Hochladen deines Sprinkles: ${error.message}`,
        });
      }
    }

    if (!uploadState.uploaded) {
      if (
        state.title !== undefined &&
        state.text !== undefined &&
        state.langCodeIso6391 !== undefined &&
        state.pictureBase64String !== undefined &&
        state.geofenceCenter !== undefined
      ) {
        const data: Data = {
          title: state.title,
          text: state.text,
          langCodeIso6391: state.langCodeIso6391,
          picture: {
            mimeType: state.pictureBase64String.split(";")[0].split(":")[1], // data:image/jpg;base64,<base64 data>
            dataBase64: state.pictureBase64String.split(";")[1].split(",")[1], // data:image/jpg;base64,<base64 data>
          },
          pictureSourceText: state.pictureSourceText,
          pictureSourceUrl: state.pictureSourceUrl,
          geofenceCenter: {
            lat: state.geofenceCenter.lat(),
            long: state.geofenceCenter.lng(),
          },
          textSourceLogo:
            state.textSourceLogoId !== undefined
              ? state.textSourceLogoId
              : state.textSourceLogoBase64String !== undefined
              ? {
                  mimeType: state.textSourceLogoBase64String.split(";")[0].split(":")[1], // data:image/jpg;base64,<base64 data>
                  dataBase64: state.textSourceLogoBase64String.split(";")[1].split(",")[1], // data:image/jpg;base64,<base64 data>
                }
              : undefined,
          textSourceText: state.textSourceText,
          textSourceUrl: state.textSourceUrl,
          createdById: loginState.user?.id,
          curated: loginState.user !== undefined ? loginState.user.curator : false
        };

        upload(data);
      } else {
        setUploadState({
          uploaded: false,
          errorMsg: "Nicht alle Daten wurden korrekt erfasst.",
        });
      }
    }
  }, [state, uploadState.uploaded, dispatch]);

  const content = (
    <div className="flex flex-col h-full justify-start items-center pt-12 bg-dark bg-center bg-no-repeat bg-congrats bg-contain">
      <div className="text-white text-center">
        {uploadState.uploaded && (
          <p>
            <span className="font-bold text-xl">Gratulation</span> <br />
            zu deinem Sprinkle
          </p>
        )}
        {!uploadState.uploaded && uploadState.errorMsg === "" && (
          <p>Dein Sprinkle wird erstellt ...</p>
        )}
        {!uploadState.uploaded && uploadState.errorMsg !== "" && <p>{uploadState.errorMsg}</p>}
      </div>
    </div>
  );

  if (uploadState.uploaded || uploadState.errorMsg !== "") {
    return <Link to="/">{content}</Link>;
  }
  return content;
};
