import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useReducer } from "react";
import { Congrats } from "../components/Congrats";
import { CreateSprinkleFrame } from "../components/CreateSprinkleFrame";
import { LoginState, reducer } from "../state";
import { CreateText } from "../components/CreateText";
import { Wrapper } from "@googlemaps/react-wrapper";
import { CreateLocation } from "../components/CreateLocation";
import { CreatePicture } from "../components/CreatePicture";
import { URL } from "../urls";

export const CreateSprinkle: React.FC<{ loginState: LoginState }> = ({ loginState }) => {
  const [state, dispatch] = useReducer(reducer, {
    langCodeIso6391: "de",
    pictureChanged: false,
    textSourceLogoChanged: false,
  });

  return (
    <Routes>
      <Route
        path="congrats"
        element={<Congrats state={state} loginState={loginState} dispatch={dispatch} />}
      />
      <Route path="*" element={<CreateSprinkleFrame state={state} />}>
        <Route path="" element={<Navigate to={`${URL.createSprinkle}/text`} />} />
        <Route path="text" element={<CreateText dispatch={dispatch} state={state} />} />
        <Route path="picture" element={<CreatePicture dispatch={dispatch} state={state} />} />
        <Route
          path={"location"}
          element={
            <Wrapper apiKey="AIzaSyBCYb6XFxcZ_QXw8t6vUbZUCMG9kD0PFlM">
              <CreateLocation dispatch={dispatch} state={state} />
            </Wrapper>
          }
        />
      </Route>
    </Routes>
  );
};
