import React from "react";
import { Link } from "react-router-dom";

export const UploadButton: React.FC<{
  to: string;
}> = ({ to }) => {
  return (
    <Link to={to} className="absolute bottom-0 left-1/2 -translate-x-12 mb-safe">
      <div className="flex flex-col justify-center items-center cursor-pointer w-24 h-24 rounded-full bg-white">
        <div className="flex flex-col justify-center items-center w-16 h-16 rounded-full bg-sprinkle text-white font-bold text-sm">
          Upload
        </div>
      </div>
    </Link>
  );
};
